import styled from "styled-components";
import {FaBars,FaTimes} from "react-icons/fa"
import {size} from "../devices"
import { MainTheme } from "../theme";

export const Hamburger = ({IsOpen}) => {
    //Viser x og skal lukkes
  if(IsOpen){
    return(
      <>
        <FaTimes/>
      </>
    )
  } 
  //Viser Bars for å åpne
  else {
    return(
      <>
        <FaBars/>
      </>
    )
  }
}


export const Bars = styled.div`
  font-size: 1rem;
  @media screen and (min-width: ${size.tablet}) {
    display: none;
    /* border: 1px red solid; */
  }
  @media screen and (max-width: ${size.tablet}) {
    display: block;
    margin-right: 2rem;
    /* border: 1px red solid; */
  }
`;

export const Nav = styled.nav`
    position: sticky;
    top: 0;
    padding: 0;
    background-color: ${props => props.theme.white};
    height: ${props => props.theme.navbar_height};
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 12;

    

`;
export const NavLogo = styled.div`

  display: flex;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: 25rem;
  /* border: 1px red solid; */

  img{
    /* border: 1px red solid; */
    height: 40px;

    @media screen and (max-width: ${size.tablet}) {
    margin-left: 2rem;
  }
  }
  h2{
    color: #1B1C1E;
    padding: 0 10px 0 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 20px;
    
    /* border: 1px red solid; */


    &:hover {
      transition: all 0.2s ease-in-out;
      color: #FFBC01;

  }
  }

`;

export const NavLink = styled.a`
    color: #1B1C1E;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    font-size: 24px;
    cursor: pointer;


  &:hover {
    color: #FFBC01;
  }

  @media screen and (max-width: ${size.tablet}) {
   font-size: 3rem;
   &:hover {
    color: ${props => props.theme.white};
  }

  }

 
  
`;

export const NavBtnLink = styled(NavLink)`
  border-radius: 4px;
  color: black;
  background: transparent;
  padding: 10px 22px;
  background-color: #FFBC01;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 24px;
  margin-left: 24px;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    color: ${props => props.theme.white};
  }


  @media screen and (max-width: ${size.tablet}) {
   font-size: 3rem;
   background-color: ${props => props.theme.white};
   padding: 0.4rem;
   margin: 0;

   &:hover {
    color: #FFBC01;
    padding: 0.4rem;
  }

  }


`;



export const NavMenu = styled.div`


  display: flex;
  align-items: center;
  padding: 2rem;

  @media screen and (max-width: ${size.tablet}) {
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;
   height: ${MainTheme.height};
   top: ${MainTheme.navbar_height};
   width: 100%;
   position: fixed;
   background-color: #FFBC01;
   padding: 0;
   margin: 0;
   opacity: ${props => props.IsOpen === true? 1 : 0};
   transition: ${props => props.IsOpen === true? "all 0.2s" : "none"};
   display: ${props => props.IsOpen === true? "flex" : "none"};



   ${NavLink} {
    font-size: 2rem;
    height: 2rem;
   }


  }
`;


