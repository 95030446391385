import React from "react";
import styled from "styled-components";
import tamales from "../../images/menu/tamales.jpeg"
import { MainTheme,NavHeightPercentage } from "../../theme";
import {device} from "../../devices"

const StyledLandingMenu = styled.div`

    width: 100vw;
    height: ${MainTheme.height};
    position: relative;
    overflow: hidden;

    img {
        z-index: -1;
        position: absolute;
        object-fit:cover;
        height: 100%;
        width: inherit;
       }
`;

const InfoMenu = styled.div`

    position: absolute;
    background-color: #162D19;
    left: 10rem;
    top: 4rem;
    width: 40vw;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;


    h1{
       color: ${props => props.theme.white};
       font-size: 4rem;
       margin: 0 0 0.5rem 0;
    }


    @media ${device.mobileM}{

        left: 0;
        top: 2vh;
        width: inherit;
        padding: 0%;

        h1{
            margin: 2rem 0 2rem 0;;
        }

    }

    @media ${device.tablet}{
        left: 10rem;
        top: 4rem;
        width: 50vw;
        padding: 2%;

        h1{
            color: ${props => props.theme.white};
            font-size: 4rem;
        /* border: 1px ${props => props.theme.white} solid; */
        margin: 0 0 0.5rem 0;
        }
    }

    @media ${device.laptop}{
        position: absolute;
        background-color: #162D19;
        left: 10rem;
        top: 4rem;
        width: 50vw;
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        
    }



`;

const ButtonAndText = styled.div`
    margin: 0rem;
    p{
        /* border: 1px ${props => props.theme.white} solid; */
        font-family: 'Geo', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        color: ${props => props.theme.white};
    }



    @media ${device.mobileM}{
        margin: 0 1rem 0 1rem;
        p{

        }
    }

    @media ${device.tablet}{
    }

    @media ${device.laptop}{
        margin: 0rem;
        p{
            /* border: 1px ${props => props.theme.white} solid; */
            font-family: 'Geo', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            color: ${props => props.theme.white};
        }
    }


`;

const ButtonContainer = styled.div`
    display: flex;
    /* border: 1px ${props => props.theme.white} solid; */
    justify-content: space-around;
    width: inherit;
`;

const CustomButton = styled.button`
    font-family: 'Geo', sans-serif;
    font-style: normal;
    font-weight: normal;
    background-color: #FFBC01;
    border: none;
    opacity: 0.6;
    font-size: 1.3rem;
    border-radius: 2px;

    &:hover{
        opacity: 1;
        cursor: pointer;
    }
`;



const LandingMenu = ({listOfRefs}) => {

    function scrollToElement(refObject){
        window.scrollTo({top: refObject.current.offsetTop-getYcoord(), behavior: 'smooth'})
    }

    function getYcoord(){
        return( NavHeightPercentage * window.innerHeight)
    }

    return(

    <StyledLandingMenu >

        <InfoMenu>
            <h1> Meny </h1>
            
            <ButtonAndText> 

            <ButtonContainer>
                <CustomButton onClick={() => scrollToElement(listOfRefs[0])} > Forrett </CustomButton>
                <CustomButton onClick={() => scrollToElement(listOfRefs[1])}> Hovedrett </CustomButton>
                <CustomButton onClick={() => scrollToElement(listOfRefs[2])}> Dessert </CustomButton>
            </ButtonContainer>
                <p>
                    Vi bruker kun råvarer av topp kvalitet og tilbereder dem på beste måte med 
                    vår erfaring fra det latinamerikanske kjøkkenet. 
                    Alle rettene kan kombineres etter behov.
                </p>    
            </ButtonAndText>
        </InfoMenu>
        <img src={tamales} alt="food_picture" />


    </StyledLandingMenu>
    )

} 


export default LandingMenu;