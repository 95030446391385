import React from "react";
import styled from "styled-components";
import sinna from "../images/contact/sinna.png"
import { MainTheme } from "../theme";
import { device } from "../devices";

const MainContainer = styled.div`
display: flex;
//width: 100%;
justify-content: space-between;
width: 100vw;
height: ${MainTheme.height};

`

const LeftContainer = styled.div`


   
    @media ${device.mobileS}{
        display: none;       
}

    @media ${device.tablet}{
    background-color: #162D19;
    width: calc(100vw * 1/3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    img{
        height: 60%;
        position: absolute;
        top: 100px;
    }

    @media ${device.laptop}{
        img{
        height: 70%;
        position: absolute;
        top: 100px;
    }
    }

        
    }
    

`;

const RightContainer = styled.div`
    @media ${device.mobileS}{
    width: 100%;
    overflow:hidden;
    background-color: #FFBC21;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;


        h1{
            color: ${props => props.theme.white};
            font-size: 4rem;
            margin: 2rem;
        }
        h2{
            font-size: 1.5rem;
        }

        p{
            font-size: 2rem;
            margin: 1rem;
        }
       
}

    @media ${device.tablet}{
        width: calc(100vw * 2/3);
        overflow:hidden;
        background-color: #FFBC21;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;


        h1{
            color: ${props => props.theme.white};
            font-size: 8rem;
            margin: 2rem;
        }
        h2{
            font-size: 2rem;
        }

        p{
            font-size: 2rem;
        }
     
}


`

const Contact = ({ innerRef }) => {

     return (
        <MainContainer ref={innerRef}> 
            <LeftContainer>
                <img src={sinna} alt="sinnataggen" />
                </LeftContainer>
            <RightContainer>
                <h1> Kontakt </h1>

                <p> For å kunne best tilpasse bestilling til dine arrangmenter ber vi deg ta kontakt på mail for en uforpliktende prat</p>
                <h2> MachuPicchuCatering(at)gmail.com</h2> 

                </RightContainer>
        </MainContainer>
    );
};

export default Contact;