import ceviche from "../../../images/menu/main_course/ceviche.jpeg"
import aji_de_gallina from "../../../images/menu/main_course/aji_de_gallina.jpeg"
import lomo_saltado from "../../../images/menu/main_course/lomo_saltado.jpeg"

const ceviche_obj = {
    id: 0,
    title: "Ceviche", 
    description: "Ceviche er rå fisk marinert i sitrus. Serveres med løk, chili og koriander",
    image: ceviche
}

const aji_de_gallina_obj = {
    id: 1,
    title:"Aji De Gallina", 
    description: "Peruansk kyllingstue servert med ris eller poteter. Serves med typiske peruanske krydder",
    image: aji_de_gallina
}

const lomo_obj = {
    id: 2,
    title: "Lomo Saltado", 
    description: "Peruansk lammegryte kombineres med løk, tomater, pommes frites og andre ingredienser",
    image: lomo_saltado
}


const main_course_list = [ceviche_obj, aji_de_gallina_obj, lomo_obj]

export default main_course_list;
