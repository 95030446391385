import React from "react";
import styled from "styled-components";
import home_logo from "../images/home/home_logo.png"
import home_food from "../images/home/home_aji_de_gallina.jpeg"
import {device} from "../devices"



const MainContainer = styled.div`
    width: 100vw;
    height: ${props => props.theme.height};
    display: flex;
    justify-content: flex-start;   
    position: relative;
    overflow: hidden;

`

const LeftContainer = styled.div`
    background-color: ${props => props.theme.primary_color};
    width: calc(100vw * 1/3);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;


    img{
        width: 60%;
        margin-bottom: 0;
        margin-top: 2rem;
        padding: 0;
    }
    h2{
        text-align: center;
        color: ${props => props.theme.white};
        font-weight: 400;
        font-size: 400%;
        margin: 0;
    }
    p{
        font-size: 200%;
        margin: 0;
        text-align: center;
        color: #000000;
    }
    
    @media ${device.mobileM}{ 
    
        background-color: transparent;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: -1em;

        h2{
            margin-right: 1rem;
        }

        p{
            margin-right: 1rem;
        }

        img{
            width: 50%;
            margin-bottom: 0;
            margin-top: 2rem;
            padding: 0;
        }

        
    }
    



     // For large devices. This entails tablet landscape
     @media ${device.tablet} {
        background-color: ${props => props.theme.primary_color};
        width: calc(100vw * 1/3);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: relative;

        h2{
            font-size: 400%;
            padding: 0 0.5rem 0 0.5rem; 
        }
        p{
            font-size: 2rem;
            padding: 0 0.5rem 0 0.5rem;
            }       

        img{
            width: 80%;
            margin-bottom: 0;
            margin-top: 2rem;
            padding: 0;
        }
 
    }
    // For large devices AND IPAD LANDSCAPE!!
    @media ${device.laptop} {

        background-color: ${props => props.theme.primary_color};
        width: calc(100vw * 1/3);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        position: relative;

        h2{
            font-size: 7vw;
            padding: 0 0.5rem 0 0.5rem; 
        }
        p{
            font-size: 2rem;
            padding: 0 0.5rem 0 0.5rem;
            }       

        img{
            width: 60%;
            margin-bottom: 0;
            margin-top: 2rem;
            padding: 0;
        }
 
    }
   `;



const PictureContainer = styled.div`
    width: calc(100vw * 2/3);
    //background-image: url(${home_food});
    position: relative;
    overflow: hidden;

    img{
        object-fit: scale-down;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    @media ${device.mobileM}{   
        width: 100%;
        height: 100%;
        //background-image: url(${home_food});
        position: relative;
        overflow: hidden;

        img{
        object-fit: scale-down;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: -50%;
        filter: blur(2px);
        -webkit-filter: blur(2px);
        -moz-filter: blur(2px);
        -o-filter: blur(2px);
        -ms-filter: blur(2px);   


        }
    }

    @media ${device.mobileM}{   

        img{
        object-fit: scale-down;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 0%;
    }
             
    }

    @media ${device.tablet} {
        position: relative;
        overflow: hidden;       
        width: calc(100vw * 2/3);


        img{
        object-fit: scale-down;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: -45%;
        }

    }

    // For large devices 
    @media ${device.laptop} {
        width: calc(100vw * 2/3);
        //background-image: url(${home_food});
        position: relative;
        overflow: hidden;


    img{
        object-fit: scale-down;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 0;

        filter: blur(0px);
        -webkit-filter: blur(0px);
        -moz-filter: blur(0px);
        -o-filter: blur(0px);
        -ms-filter: blur(0px);  
        }
    }

    
`

const Home = ({ innerRef }) => {

    return (

        <MainContainer ref={innerRef}> 
            <LeftContainer>
                <img src={home_logo} alt="logo_picture"/>
                <h2> Peruansk Mat I Oslo </h2>
                <p> Ekte Latin Amerikansk mat til deg! </p>
            </LeftContainer>

            <PictureContainer>
                <img src={home_food} alt="home_food" onClick={(e) => console.log(e)} />
                
            </PictureContainer>
        </MainContainer>
    );
};

export default Home;