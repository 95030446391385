import dessert_list from "./DessertObject";
import { useState } from "react";
import styled from "styled-components";
import {
    CSSTransition,
    TransitionGroup,
  } from 'react-transition-group';

import { MainTheme } from "../../../theme";


const MainContainer = styled.div`
    //width: 100%;
    justify-content: space-between;
    display: flex;
    width: 100vw;
    height: ${MainTheme.height};

`;

const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    width: 14rem;
    opacity:  ${props => props.focused ? 1 : 0.6};


    h3{
        color: #FFBC21;
        font-size: 1.9rem;
        font-weight: 400;
        margin-bottom: 1rem;
        text-align: center;
    }

    p{
        color: ${props => props.theme.white};
        margin: 0;
        font-size: 1.1rem;
        text-align: center;
    
    }
`;

const LeftContainer = styled.div`
    background-color: #162D19;
    box-sizing: border-box;
    width: calc(100vw * 1/3);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0 2rem 0;


    h1{
        margin: 0;
        padding: 0;
        font-size: 3rem;
        color: ${props => props.theme.white};
    }
`

const RightContainer = styled.div`
    width: calc(100vw * 2/3);
    overflow:hidden;
    background-color: #FFBC21;
    position: relative;

    .item-enter {
  opacity: 0;
    }
    .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
    }
    .item-exit {
    opacity: 1;

    }
    .item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;

}

    img{
        overflow:hidden;
        z-index: 4;
        position: absolute;

      

    &.entry_number_0{
        bottom: 0rem;
        height: 100%;
    }

    &.entry_number_1{
        object-fit: cover;;
        width: 100%;
        height: 100%


    }

    &.entry_number_2{
        object-fit: cover;
        bottom: 0rem;
        height: 100%;
    }







    }
`


const DesktopDessert = ({innerRef}) => {

    const list_of_food = dessert_list;
    const [FocusBox, setFocusBox] = useState([false,true,false])
    let tempBox = FocusBox;
    const [FocusPicture, setFocusPicture] = useState(list_of_food[1].image)


    function getTrueIndex(){
        return FocusBox.indexOf(true)
    }


    function hoverHandler(index){
        setFocusPicture(list_of_food[index].image)
        tempBox.fill(0, false)
        tempBox[index] = true
        setFocusBox(tempBox)

        

    }

    return(
        <MainContainer ref={innerRef}>

            <LeftContainer>
                <h1> Dessert </h1>
                {list_of_food.map(
                    (food) => (<TitleAndDescription key={food.id} focused={FocusBox[food.id]}   
                        onMouseEnter={() => hoverHandler(food.id)}>
                        <h3> {food.title} </h3>
                        <p>  {food.description} </p>
                    </TitleAndDescription>
                    )
                )}

            </LeftContainer>



                <TransitionGroup component={RightContainer}>
                    <CSSTransition
                        key={FocusPicture}
                        timeout={500}
                        classNames="item"
                        >
                        <img src={FocusPicture} alt="Food" className={`entry_number_${getTrueIndex()}`}/>


                    </CSSTransition>
                </TransitionGroup>


        </MainContainer>
        
      

    )

} 


export default DesktopDessert;