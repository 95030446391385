import './App.css';
import Navbar from './components/NavBar/Index';
import Home from './components/pages/home';
import About from './components/pages/about';
import Menu from './components/pages/Menu/Index';
import Contact from './components/pages/contact';
import React,{useState} from 'react';
import { links } from './components/pages/data';
import GlobalStyle from "./components/global";
import styled, { ThemeProvider } from 'styled-components';
import {MainTheme} from "./components/theme"

const MainApp = styled.div`  

`;


const App = ()=> {

  const [MobileMenuOpen, setMobileMenuOpen] = useState(false)  
  const refObjectList = []
  links.map((link) => refObjectList.push({'id': link.id, 'title': link.text, 'ref':React.createRef()}))

  function toggleMobileMenu(){
    setMobileMenuOpen(!MobileMenuOpen)
    console.log(MobileMenuOpen)
    console.log("toggleMobileMenu was clicked")

  }
  


  return (
    <MainApp bool={MobileMenuOpen}>
      <ThemeProvider theme={MainTheme} >
        <GlobalStyle/>
        <Navbar listOfRef={refObjectList}  toggleMobileMenu={toggleMobileMenu} isMobileMenuOpen={MobileMenuOpen}/>

        <Home innerRef={refObjectList.find(x => x.title === 'Hjem').ref}/>
        <About innerRef={refObjectList.find(x => x.title === 'Om Oss').ref}/>
        <Menu innerRef={refObjectList.find(x => x.title === 'Meny').ref}/>
        <Contact innerRef={refObjectList.find(x => x.title === 'Kontakt').ref}/>
      </ThemeProvider>

    </MainApp>
  )


  
};

export default App;
