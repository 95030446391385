import causa_image from "../../../images/menu/entry/causa.jpeg"
import choros_image from "../../../images/menu/entry/choros.jpeg"
import yuca_image from "../../../images/menu/entry/yuca.jpeg"

const causa_obj = {
    id: 0,
    title: "Causa a la Limena", 
    description: "Potetrett fylt med kylling, ris, gulrot, erter og toppes med egg og oliven. Serveres kaldt",
    image: causa_image
}

const choros_obj = {
    id: 1,
    title: "Choritos a la chalaca", 
    description: "Blåskjell fylt med rødløk, tomat, koriander og mais. Serveres kaldt",
    image: choros_image
}

const yuca_obj = {
    id: 2,
    title: "Yukitas fritas ", 
    description: "Frityrstekt kassava også kalt “Yuca”. Et sunt alternativ til pomme frites",
    image: yuca_image
}


const entry_list = [causa_obj, choros_obj, yuca_obj]
export default entry_list;
