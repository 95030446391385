import React from "react";
import logo from "../images/logo.png"
import { links } from "../pages/data";
import {NavHeightPercentage } from "../theme";

import {
    Nav,
    NavLogo,
    NavLink,
    NavMenu,
    NavBtnLink,
    Bars,
    Hamburger
} from "./NavbarElements";



/*
PLAN: Conditional rendering.
Eventlistener on window-resize and and state changes if the window 

*/


const Navbar = ({listOfRef,toggleMobileMenu,isMobileMenuOpen}) => {
    
    const refObjectList = listOfRef;
    function scrollToElement(ref){  
        window.scrollTo({top: ref.current.offsetTop-getYcoord(), behavior: 'smooth'})
        toggleMobileMenu()
    }
    function logoClickHandler(ref){
        window.scrollTo({top: ref.current.offsetTop-getYcoord(), behavior: 'smooth'})
    }
    function getYcoord(){
        return( NavHeightPercentage * window.innerHeight)
    }



    return (
        <>
           <Nav>
            <NavLogo onClick={() => logoClickHandler(refObjectList.find(x => x.title === 'Hjem').ref)} >
               <img src={logo} alt="logo"/>
               <h2> Macchu Picchu Catering </h2>
            </NavLogo>
            <Bars onClick={() => toggleMobileMenu()}>
                <Hamburger IsOpen={isMobileMenuOpen} />

            </Bars>
            <NavMenu IsOpen={isMobileMenuOpen}>
            {links.map((link) => {
                return(
                    link.text === 'Kontakt' ? <NavBtnLink key={link.id} onClick={() => scrollToElement(refObjectList.find(x => x.title === 'Kontakt').ref)} > {link.text }</NavBtnLink> :  
                    <NavLink onClick={() => scrollToElement(refObjectList.find(x => x.title === link.text).ref)} key={link.id}>  {link.text}  </NavLink> 
                    )
                })}
            </NavMenu> 
           </Nav> 
        </>
    );
};
export default Navbar;