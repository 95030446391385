export const NavHeight = 12;
export const NavHeightPercentage = 0.12;


export const MainTheme = {
    primary_color: "#FFBC01",
    secondary_color: "#162D19",
    navbar_height: `${NavHeight}vh`,
    height: `calc(100vh - ${NavHeight}vh)`,
    white: "whitesmoke",
    small_container: "calc(100vw * 1/3)",
    big_container: "calc(100vw * 2/3)",

}