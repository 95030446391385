//Globalstyles.js

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    @import url('https://fonts.googleapis.com/css2?family=Geo:ital@0;1&display=swap');    
    font-family: 'Geo', sans-serif;
    font-style: normal;
    font-weight: normal;
  }
`;
 
export default GlobalStyle;
