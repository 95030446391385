import React from "react";
import styled from "styled-components";
import chef from "../images/about/milvia.jpeg"
import mp_cutout from "../images/about/mp_cutout.png"
import { device } from "../devices"
import { MainTheme } from "../theme";


const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: ${MainTheme.height};

    @media ${device.mobileM}{
        width: 100vw;

       
    }

    @media ${device.tablet}{
  
       
    }


`


const LeftContainer = styled.div`
    background-color: ${props => props.theme.secondary_color};
    width: calc(100vw * 1/3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    p {
        color: ${props => props.theme.white};
        width: 80%;      
        font-size: 100%;
    }

    @media ${device.mobileM}{
        width: 100vw;

       
    }

    @media ${device.tablet}{
        background-color: ${props => props.theme.secondary_color};
        width: calc(100vw * 1/3);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`;


const ImageAndTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    img{
        width: 50%;
        margin-top: 2rem;
        margin-bottom: 5%;
    }
    h3{
        text-align: center;
        color: ${props => props.theme.primary_color };
        font-weight: 400;
        font-size: 150%;
        margin: 0 1rem 0 1rem;

    }

    
    @media ${device.mobileM}{
        img{
            width: 40%;
        }
        
    }

    @media ${device.tablet}{
        img{
            width: 60%;
        }
    }


    @media ${device.laptop}{
       img{
        width: 50%;
        margin-top: 2rem;
        margin-bottom: 5%;
    }
        h3{
            text-align: center;
            color: ${props => props.theme.primary_color };
            font-weight: 400;
            font-size: 150%;
            margin: 0 1rem 0 1rem;
        }
    }





`;



const PictureContainer = styled.div`

    height: ${MainTheme.height};
    width: calc(100vw * 2/3);
    overflow:hidden;
    background-color: #FFBC21;
    position: relative;



    img{
        height:inherit;
        object-fit: contain;
        position: absolute;
        z-index: 4;
    }

    h1{
        font-size: 24rem;
        color: ${props => props.theme.white};
        position: absolute;
        overflow: hidden;
        top: -35%;
        left: 10%;
        font-weight: 200;
    }


    @media ${device.mobileM}{
        display: none;
    }

    @media ${device.tablet}{
        display: inherit;
        
        h1{
        font-size: 14rem;
        top: -20%;
        left: 10%;
        font-weight: 200;
    }

        img{
            left: -70%;
            bottom: -1%;

        }
       
    }

    @media ${device.laptop}{
        
        h1{
        font-size: 17rem;
        color: ${props => props.theme.white};
        position: absolute;
        left: 5%;
        top: 1%;
        margin: 0;
        font-weight: 200;
        }


        img{
            left: inherit;
        }
       
    }


`




const About = ({innerRef}) => {

    
     return (
        <MainContainer ref={innerRef}> 
            <LeftContainer>
                <ImageAndTitle> 
                    <img src={chef} alt="logo_picture"/>
                    <h3> Milvia Silvana Anchissi Joner </h3>
                </ImageAndTitle>
                    <p> 
                        Machu Picchu Catering ble startet i 2021 av Milvia Silvana Anchissi Joner.
                        Hun kommer fra Peru og har bodd i Norge i 27 år. 
                        Hennes lidenskap er god mat og har jobbet som kokk hele livet sitt.
                        I Machu Picchu Catering mener vi at god mat er noe man ikke burde reise 
                        langt for å få tak i og tilbyr derfor en sørlig gastronomisk opplevelse til alle dine arrangementer.
                        Vi har samlet de beste smakene fra det peruanske, italienske, 
                        spanske og latinamerikanske kjøkkenet og ønsker å tilby spennende 
                        og minneverdige smaksopplevelser tilpasset dere. 
                    </p>
            </LeftContainer>

            <PictureContainer>
                <h1> Hola! </h1>
                <img src={mp_cutout} alt="Machu Picchu" />
            </PictureContainer>
        </MainContainer>
    );
};

export default About;