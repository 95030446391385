import dessert_list from "./DessertObject";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { MainTheme } from "../../../theme";
import DesktopDessert from "./Desktop_Dessert";
import MobileDessert from "./Mobile_Dessert";


const MainContainer = styled.div`
    width: 100vw;
    background-color: ${MainTheme.secondary_color};
`;


const Dessert = ({innerRef}) => {
    const list_of_food = dessert_list;
     const [PageWidth, setPageWidth] = useState(window.innerWidth)

    useEffect(() => {
        function printSize() {
         setPageWidth(window.innerWidth)}
         window.addEventListener("resize", printSize)
        }
    )
    return(
        <MainContainer>
            {PageWidth <= 768 ?   <div ref={innerRef}>
                <MobileDessert food_list={list_of_food}/>
            </div> :  <div ref={innerRef}>
                <DesktopDessert/>
            </div>}
        </MainContainer>
    )
    

} 


export default Dessert;