import main_course_list from "./MainCourseObject";
import { useState, useEffect } from "react";
import MobileMainCourse from "./Mobile_MainCourse";
import DesktopMainCourse from "./Desktop_MainCourse";
import styled from "styled-components";
import { MainTheme } from "../../../theme";

const MainContainer = styled.div`
    width: 100vw;
    background-color: ${MainTheme.secondary_color};
`;


const MainCourse = ({innerRef}) => {
    const list_of_food = main_course_list;
     const [PageWidth, setPageWidth] = useState(window.innerWidth)

    useEffect(() => {
        function printSize() {
         setPageWidth(window.innerWidth)}
         window.addEventListener("resize", printSize)
        }
    )
    return(
        <MainContainer>
            {PageWidth <= 768 ?   <div ref={innerRef}>
                <MobileMainCourse food_list={list_of_food}/>
            </div> :  <div ref={innerRef}>
                <DesktopMainCourse/>
            </div>}
        </MainContainer>
    )
    

} 


export default MainCourse;