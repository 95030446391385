import React from "react";
import Entry from "./Entry";
import MainCourse from "./MainCourse";
import Dessert from "./Dessert/Index";
import LandingMenu from "./landing_menu";
import styled from "styled-components";


const MotherDiv = styled.div`
    display: grid;
`;

const Menu = ({innerRef}) => {
    const entryRef =  React.createRef()
    const mainCourseRef = React.createRef();
    const dessertRef = React.createRef();
    const listOfRefs = [entryRef,mainCourseRef,dessertRef]

    return(
        <MotherDiv ref={innerRef}> 
            <LandingMenu listOfRefs={listOfRefs}/>
            <Entry innerRef={entryRef} />
            <MainCourse innerRef={mainCourseRef}/>
            <Dessert innerRef={dessertRef}/>
        </MotherDiv>

    )
}


export default Menu;