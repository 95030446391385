export const links = [
    {id: 1,
    text:"Hjem",
    url:'#home'},
      
    {id: 2,
    text:"Om Oss",
    url:'#about'},
    
    {id: 3,
    text:"Meny",
    url:'#menu'},

    {id: 4,
    text:"Kontakt",
    url:'#contact'},

]