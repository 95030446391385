import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { MainTheme } from "../../../theme";
import { Carousel } from 'react-responsive-carousel';
import {FaArrowAltCircleRight,FaArrowAltCircleLeft} from "react-icons/fa"

const FwdArrow = styled(FaArrowAltCircleLeft)`
    position: absolute;
    z-index: 2;
    top: 'calc(50% - 15px)';
    width: 30;
    height: 30;
    cursor: 'pointer';
`

const BckArrow = styled(FaArrowAltCircleRight)`
    position: absolute;
    z-index: 2;
    top: 'calc(50% - 15px)';
    width: 30;
    height: 30;
    cursor: 'pointer';
`


const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    color: `${MainTheme.primary_color}`,
};


const MainContainer = styled.div`

    display: grid;
    overflow: hidden;
    width: 100vw;
    height: ${MainTheme.height};
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr 6fr 2fr;
    grid-gap: 1rem; 



`;

const FoodType = styled.h1`
  
    font-size: 3rem;
    color: ${props => props.theme.white};
    text-align: center;
    grid-column: 2;
    grid-row: 1;

`


const TextContainer = styled.div`
    /* background-color: navy;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0; */


    grid-column: 2;
    grid-row: 1;

        

    
    h1{
        margin: 0;
        padding: 0;
        font-size: 3rem;
        color: ${props => props.theme.white};
    }
`



const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;

  

    h3{
        color: #FFBC21;
        font-size: 2rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    p{
        color: ${props => props.theme.white};
        margin: 0;
        font-size: 1.2rem;
    }
`;

const ImageContainer = styled.div`
    grid-column: 2;
    grid-row: 2;
    position: relative;
    overflow: hidden;


    img{
        border: 1px solid blue;

        height: 100%;
        object-fit: cover;

    } 
`

/**
* Functional Component that generates a card for displaying food
* @param    {object} food_object    
food_object containing image, title and description.
* @return   {JSX.Element}         Greeting message
*/
const MobileDessert =  ({food_list}) => {
    return(
        <> 
        <FoodType> Dessert</FoodType>

        <Carousel showThumbs={false} showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
                <FwdArrow  onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}/>
            )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
                <BckArrow onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}/>
            )
        }>
                
        {food_list.map((food) => 
            <div  key={food.id}>
            <MainContainer >
                    <TextContainer>
                        <TitleAndDescription>
                            <h3> {food.title} </h3> 
                            <p>  {food.description} </p>    
                        </TitleAndDescription>
                    </TextContainer>
                    <ImageContainer>
                            <img src={food.image} alt="Food" className={`food_number_${food.id}`}/>
                    </ImageContainer>
                    </MainContainer>
                </div>
        )}
            </Carousel>


    </>
    )
}


export default MobileDessert;