import React,{useEffect} from "react";
import entry_list from "./EntryObject";
import { useState } from "react";
import styled from "styled-components";
import { MainTheme } from "../../../theme";
import DesktopEntry from "./Desktop_entry";
import MobileEntry from "./Mobile_entry";

const MainContainer = styled.div`
    width: 100vw;
    background-color: ${MainTheme.secondary_color};
`;



const Entry =  ({innerRef})=> {

    const list_of_food = entry_list;
    const [PageWidth, setPageWidth] = useState(window.innerWidth)

    useEffect(() => {
        function printSize() {
         setPageWidth(window.innerWidth)}
         window.addEventListener("resize", printSize)
        }
    )
    return(
        <MainContainer>
            {PageWidth <= 768 ?   <div ref={innerRef}>
                <MobileEntry food_list={list_of_food}/>
            </div> :  <div ref={innerRef}>
                <DesktopEntry/>
            </div>}
        </MainContainer>
    )
}




export default Entry;