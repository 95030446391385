import alfajores from "../../../images/menu/dessert/alfajores.jpeg"
import mazamorra from "../../../images/menu/dessert/mazamorra.jpg"
import turron from "../../../images/menu/dessert/turron.jpeg"


const alfa_obj = {
    id: 0,
    title: "Alfajores", 
    description: "Peruansk konfekt laget med kondensert melk og toppet med kokos",
    image: alfajores
}

const maza_obj = {
    id: 1,
    title: "Mazamorra Morada", 
    description: "Peruansk delikatesse laget av lilla mais. Tilbredes med ananas, kanel og søtpotetmel",
    image: mazamorra
}

const turron_obj = {
    id: 2,
    title: "Turron", 
    description: "Konfekt laget av  av mandler, honning, sukker og eggehvite.",
    image: turron
}


const dessert_list = [alfa_obj, maza_obj, turron_obj]
export default dessert_list;
